body {
  background-color: #fff!important;
  color: #1a1a1a!important;
}

h1, h2, h3, h4 {
  text-transform: uppercase;
  font-family: 'brandon-grotesque', Arial, Helvetica, sans-serif;
  z-index: 2;
}

h2, h3, h4 {
  letter-spacing: 0.6px;
}

h3 {
  font-size: 1.17em!important;
}

h4 {
  color: #024f74;
  font-size: 18.72px!important;
  margin-bottom: 20px!important;
  font-weight: bold!important;
}

button:focus {
  outline: none!important;
}

.site-wrapper {
  background-color: #fff;
  color: #1a1a1a;
  margin-top: 76px;
}

.headings {
  text-align: center;
  font-size: 1.17em;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 50px;
  letter-spacing: .6px;
}

/** Sections **/
#header-image {
  /* background-image: url("img/startseite_header.jpg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 540px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.layout-wrapper {
  width: 100%;
  position: relative;
}

.content-wrapper {
  width: 90%;
  position: relative;
  max-width: 1360px;
  margin: 0 auto;
  box-sizing: content-box;
  padding: 0 1rem;
}

#header-image h2 {
  position: relative;
  margin: 0;
  min-height: 50px;
  background-color: #fff;
  font-size: 1.8rem;
  font-weight: 900!important;
  padding-top: .8rem;
  letter-spacing: .8px;
}

#header-image h2::before {
  border-radius: 100% 0 0 0;
  content: '';
  display: block;
  position: absolute;
  right: 100%;
  top: 0;
  bottom: 0;
  width: 40px;
  background-color: #fff;
}

#header-image h2::after {
  content: '';
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
}

.layout-helper {
  position: absolute;
  top: -125px;
  right: 0;
}

.titleSpan {
  font-weight: 400;
  float: right;
  display: block;
  z-index: 2;
}


#start, #details {
  padding: 6.5rem 0;
}

#wissensportale, #termine {
  padding: 6.5rem 0 12.5rem 0;
}

#start h1 {
  color: #a90e62;
  font-size: 3.8rem;
  margin: 0.83em 0;
  font-weight: 700;
}

#wissensportale {
  background-color: #f2f2f2;
}

@media only screen and (max-width: 960px) {
  #start h1 {
    font-size: 40px;
  }

  .titleSpan{
    float: none;
  }
}

@media only screen and (max-width: 600px) {
  #header-image h2{
    font-size: 20px;
    font-weight: 700!important;
  }
  .titleSpan{
    font-size: 20px;
  }
  #header-image {
    height: 300px;
  }
}

