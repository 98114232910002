#wysiwyg h2{
    font-size: 1.2rem;
}
#wysiwyg h4{
    font-size: 1.1rem!important;
    color: #1a1a1a!important;
    margin-bottom: 8px!important;
    font-weight: 400!important;
}

#wysiwyg ul{
    list-style: none;
}

#wysiwyg ul li::before{
    content: "\25A0";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #0096d6; /* Change the color */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}

