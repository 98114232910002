nav {
    color: white!important;
    padding: 0!important;
    flex-flow: row wrap!important;
    font-family: 'brandon-grotesque', sans-serif;
}
.nav-container {
    display: flex;
    align-items: center;
    width: 100vw!important;
    padding: .5rem calc((100% - 1360px) / 2);
}

.navbar-top {
    background-color: #092A3A;
    min-height: 20px;
    justify-content: flex-end;
}

.navbar-bottom {
    background-color: #000;
}

.navbar-brand {
    padding-bottom: 4px;
}

.nav-link{
    color:white!important;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    padding-left: 20px!important;
    padding-right: 20px!important;
}

.nav-link.active, .nav-link[active] {
    color: #0096D6!important;
}

.navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.navbar-toggler {
    float: right;
}

.dropdown-menu {
    background-color: #000;
    border-radius: 0;
    padding-bottom: 0;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-item {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    padding: .25rem 1.2rem;
    position: relative;
}

.dropdown-item:hover, .dropdown-item:active, .dropdown-item.active {
    background-color: #000;
    color: #0096D6;
}


@media only screen and (max-width: 1200px) {
    .nav-container{
        padding: .5rem 1rem;
    }
}

@media only screen and (max-width: 767px) {
    .navbar-bottom {
        display: block!important;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-left: 0;
    }

}


