#footer {
    border-radius: 50px 0 0 0;
    margin-top: -50px;
}

#footer .layout-helper-right {
    margin-top: -3.1rem;
    width: 50px;
    filter: invert(1);
    float: right;
}

#footer a {
    color: #fff;
    text-decoration: none!important;
    transition: color 0.25s;
}

#footer a:hover {
    color: #0096d6!important;
    text-decoration: none!important;
}